<template>
  <div
    class="pa-0"
    style="background-color: transparent; width: 100%; height: 100%"
  >
    <div style="width: 100%; height: 95vh" class="overflow-y-auto">
      <v-progress-linear
        v-if="documents && documents.length === 0"
        color="primary"
        indeterminate
      />
      <Document :documents="documents" v-else />
    </div>
  </div>
</template>

<script>
import Document from './Document.vue';

export default {
  props: ['isCloseShow', 'documents'],
  components: { Document }
};
</script>

<style scoped>
.overflow {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.v-toolbar-title {
  align-self: center;
}

.scrollCol {
  overflow: auto;
}
</style>
