<template>
  <v-list class="pt-0">
    <v-list-item
      v-for="(item, index) in documents"
      :key="index"
      three-line
      @click="selectDocument(item)"
      :class="currentIndex === index && 'selected'"
      style="border-bottom: 0.5px solid #646569"
    >
      <v-list-item-content>
        <v-list-item-title class="ml-2 title-item">
          <div class="d-flex">
            <div v-if="item.unread" class="picker mt-1 mr-1"></div>
            {{ item.name }}
          </div>
        </v-list-item-title>
        <v-list-item-subtitle class="ml-2 subtitle-item">
          {{ formatDate(item.created) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text class="d-flex flex-row mt-auto">
          <v-img
            src="../../assets/right.png"
            width="8px"
            height="14px"
            class="image-arrow"
          />
        </v-list-item-action-text>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { auth } from '../../sharedPlugin';
import moment from 'moment';
require('moment-timezone');
export default {
  props: ['documents'],
  data: () => ({
    extensions: []
  }),
  computed: {
    ...auth.mapComputed(['user']),
    sentDate() {
      return this.document && this.document.created
        ? this.document.created?.toLocaleString()
        : '';
    }
  },
  methods: {
    selectDocument(document) {
      this.$router.push(`/documents/detail/${document.id}`);
    },
    formatDate(date) {
      var timeZoneAbbr = moment.tz(moment(), moment.tz.guess()).format('z');
      var abbr = '(' + timeZoneAbbr + ')';
      return moment(date).format('MMM DD, YYYY h:ma ') + abbr;
    }
  }
};
</script>

<style scoped>
.theme--light.v-card {
  background-color: #d3d3d3;
}
:root .theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: black;
  --v-anchor-base: #024bb9;
}
:root .theme--dark.v-card > .v-card__text,
.theme--dark.v-card .v-card__subtitle {
  color: white;
  --v-anchor-base: #75caf6;
}
.downloadButton {
  font-weight: bold;
  background-color: white;
  border-radius: 10px;
}

.image-arrow {
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 3px;
}

.title-item {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}

.subtitle-item {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.0024em;
  color: #000000;
}

.picker {
  background-color: rgb(214, 54, 110);
  border-color: rgb(214, 54, 110);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
