<template>
  <div id="support-chat" style="height: 95vh">
    <Documents :documents="documents" />
  </div>
</template>

<script>
import { api } from '../../sharedPlugin';
import Documents from '@/components/documents/Documents.vue';
export default {
  components: { Documents },
  data() {
    return {
      loading: false,
      documents: []
    };
  },
  created() {
    if (this.$route.params?.category)
      this.$store.commit('setUpdateRouteName', this.$route.params.category);
    this.getCategoryDocs();
  },
  methods: {
    getCategoryDocs() {
      this.loading = true;
      api.Documents.getAll()
        .then((res) => {
          this.documents = res.filter((item) => {
            return item.category === this.$route.params.category;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
.selected {
  background-color: #9e969654;
}

.image-arrow {
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 3px;
}

.image-ball {
  margin-left: 5px;
  margin-right: 5px;
  max-height: 10px;
  margin-top: 4px;
}

.footer {
  background-color: transparent;
  margin-top: 30px;
}
</style>
<style lang="scss">
.item-title {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #000 !important;
}

.item-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000 !important;
}

.message-preview {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000 !important;
}
</style>

<style lang="scss">
#support-chat {
  background: #fff;
  height: 100%;
  .v-menu__content {
    overflow: hidden;
  }

  .msg {
    width: 100%;
    height: 550px;
  }
}
</style>
